body {
  margin: 0;
  font-family: "Nunito", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #faf9f6;
 color:#454545
}

h1,h2,h3,h4,h5,h6{ color:#232323;}

/*
@media (prefers-color-scheme: dark) {
  body {
    background-color: #21002e;
  }
}
*/

* {
  scrollbar-width: thin;
  scrollbar-color: #ffffff rgb(240,240,250);
}

/* Works on Chrome, Edge, and Safari */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #ffffff;
}

::-webkit-scrollbar-thumb {
  background-color:rgb(240,240,250);
  border-radius: 4px;
}

/*
.MuiButton-outlined {
  border-width: 2px !important;
  font-weight: 600 !important;
}*/
